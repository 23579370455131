<template>
    <PageLayout class="chat-page">
        <div
            @drop.prevent="dragover = false"
            @dragover.prevent="dragover = true"
            @dragenter.prevent="dragover = true"
            @dragend.prevent="dragover = false"
            class="chat-page__container"
        >
            <div class="chat" ref="chat">
                <div
                    class="chat__left"
                    :class="{
                        'chat__left--full': !chat_uuid
                    }"
                >
                    <template v-if="!add_chat">
                        <Teleport to="#app" v-if="!chat_uuid">
                            <div class="fab__wrapper">
                                <Btn
                                    @click="add_chat = true"
                                    color="secondary"
                                    fab
                                    small
                                >
                                    <v-icon dark>
                                        mdi-plus
                                    </v-icon>
                                </Btn>
                            </div>
                        </Teleport>
                        <v-list>
                            <v-list-item-group
                                v-model="my_chats"
                            >
                                <template v-for="(item, index) in discuss_chatItems">
                                    <v-list-item
                                        v-slot:default="{ active }"
                                        @click="openChat(item)"
                                        class="chat-item"
                                    >
                                        <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                            <img v-if="item.chat.avatar !== '/img/avatar.png'" :src="item.chat.avatar" :alt="item.chat.name">
                                            <v-icon v-else color="primary" size="22">
                                                ${{ 'settingsIcon' }}
                                            </v-icon>
                                        </v-list-item-avatar>

                                        <v-list-item-content>
                                            <v-list-item-title class="d-flex align-center">
                                                <span class="chat-item__name">{{ item.chat.name }}</span>
                                                <div class="chat-item__new-label" v-if="item.unread_messages_count">{{item.unread_messages_count}}</div>
                                            </v-list-item-title>
                                            <template v-if="item.last_message">
                                                <v-list-item-subtitle v-if="item.last_message.type === 'text' || item.last_message.type === 'bot'" v-html="item.last_message.value"></v-list-item-subtitle>
                                                <v-list-item-subtitle v-if="item.last_message.type === 'image'">
                                                    {{ $t('photo') }}
                                                    <v-icon left x-small>
                                                        mdi-camera-outline
                                                    </v-icon>
                                                </v-list-item-subtitle>
                                                <v-list-item-subtitle v-if="item.last_message.type === 'file'">
                                                    {{ item.last_message.file_name }}
                                                    <v-icon left x-small>
                                                        mdi-paperclip
                                                    </v-icon>
                                                </v-list-item-subtitle>
                                            </template>
                                        </v-list-item-content>

                                        <v-list-item-action>
                                            <v-menu offset-y>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                        icon
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    >
                                                        <v-icon>mdi-dots-vertical</v-icon>
                                                    </v-btn>
                                                </template>
                                                <v-list>
                                                    <v-list-item>
                                                        <v-list-item-title @click="deleteChat(item)">{{ $t('delete') }}</v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </v-list-item-action>

                                    </v-list-item>
                                    <v-divider
                                        style="top:unset; position: unset"
                                        v-if="index < discuss_chatItems.length - 1"
                                        :key="index"
                                    ></v-divider>
                                </template>
                            </v-list-item-group>
                        </v-list>
                    </template>

                    <template v-if="add_chat && !chat_group_name">
                        <v-list flat>
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon @click="add_chat = false">mdi-arrow-left-thin</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title v-text="$t('new_chat')"></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>

                        <v-list subheader two-line>
                            <v-list-item-group
                                v-model="chat_group"
                                :multiple="Array.isArray(chat_group)"
                                active-class=""
                            >
                                <v-list-item v-slot:default="{ active }">
                                    <v-list-item-avatar class="border_blue">
                                        <v-icon color="primary" size="22">
                                            mdi-account-group-outline
                                        </v-icon>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title v-html="$t('new_group')"></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>

                        <v-list subheader two-line class="pb-16">
                            <v-list-item-group
                                v-model="contacts"
                                :multiple="chat_group.length > 0"
                                active-class=""
                            >
                                <template v-for="(item, index) in adminItems">
                                    <v-subheader v-if="index === 0" v-text="$t('staff')"></v-subheader>

                                    <v-list-item v-slot:default="{ active }" :key="item.title">
                                        <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                            <img v-if="item.photo !== '/img/avatar.png'" :src="item.photo" :alt="item.name">
                                            <v-icon v-else color="primary" size="22">
                                                ${{ 'settingsIcon' }}
                                            </v-icon>
                                        </v-list-item-avatar>

                                        <v-list-item-content>
                                            <v-list-item-title v-html="item.name"></v-list-item-title>
                                            <v-list-item-subtitle v-html="formatPhoneNumber(item.phone)"></v-list-item-subtitle>
                                        </v-list-item-content>

                                        <v-list-item-action v-if="chat_group.length > 0">
                                            <v-checkbox :input-value="active"></v-checkbox>
                                        </v-list-item-action>
                                    </v-list-item>

                                    <v-divider :key="index" inset style="position:unset"></v-divider>
                                </template>
                            </v-list-item-group>
                        </v-list>

                        <div v-if="typeof contacts !== 'undefined'" class="chat_button_create">
                            <v-btn
                                class="mx-2"
                                fab
                                dark
                                color="primary"
                                @click="chat_group_name = true"
                                v-if="Array.isArray(contacts) && contacts.length > 0"
                            >
                                <v-icon dark v-if="chat_group.length > 0">
                                    mdi-arrow-right-thin
                                </v-icon>
                            </v-btn>
                            <v-btn
                                class="mx-2"
                                fab
                                dark
                                color="primary"
                                v-if="!Array.isArray(contacts) && contacts !== ''"
                                @click="addChat()"
                            >
                                <v-icon dark>
                                    mdi-check-outline
                                </v-icon>
                            </v-btn>
                        </div>
                    </template>

                    <template v-if="add_chat && chat_group_name && !add_group && !edit_group">
                        <div>
                            <ValidationObserver tag="div" ref="observer" v-slot="{ invalid, validated, passes, validate }">
                                <v-list flat>
                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-icon @click="chat_group_name = false">mdi-arrow-left-thin</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title v-text="$t('new_group')"></v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                                <v-card elevation="0" class="mx-auto ">
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12">
                                                <make-group-avatar v-model="photo" aspect-ratio="1"/>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12">
                                                <ValidationProvider ref="name_group" rules="required|min:1|max:255" v-slot="{ errors, valid }">
                                                    <v-text-field
                                                        v-model="name_group" type="text"
                                                        :label="$t('name_group')"
                                                        hide-details
                                                        :error-messages="errors"
                                                        :disabled="loading"
                                                        outlined
                                                        solo
                                                        flat
                                                        dense
                                                        :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                                        color="primary"
                                                        class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                        clearable
                                                    >
                                                    </v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>

                                <div class="chat_button_create">
                                    <v-btn
                                        class="mx-2"
                                        fab
                                        dark
                                        color="primary"
                                        @click="addChatGroup()"
                                    >
                                        <v-icon dark>
                                            mdi-check-outline
                                        </v-icon>
                                    </v-btn>
                                </div>
                            </ValidationObserver>
                        </div>
                    </template>
                </div>

                <div v-if="chat_uuid" class="chat__right">
                    <template v-if="edit_group && !chat_group_name && !add_group">
                        <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                            <v-list flat>
                                <v-list-item>
                                    <v-list-item-icon>
                                        <v-icon @click="edit_group = false">mdi-arrow-left-thin</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title v-text="$t('edit_group')"></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>

                            <v-card elevation="0" class="mx-auto ">
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12">
                                            {{photo ? 'true' : 'false' }}
                                            <make-group-avatar v-model="photo" aspect-ratio="1"/>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <ValidationProvider ref="name_group" rules="required|min:1|max:255" v-slot="{ errors, valid }">
                                                <TextInput
                                                    v-model="name_group"
                                                    id="group-name"
                                                    :label="$t('name_group')"
                                                />
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-list subheader two-line class="pb-16">
                                                <v-list-item-group
                                                    v-model="contacts"
                                                    :multiple="true"
                                                    active-class=""
                                                >
                                                    <template v-for="(item, index) in users_group">
                                                        <v-subheader v-if="index === 0" v-text="$t('participants')"></v-subheader>

                                                        <v-list-item v-slot:default="{ active }">
                                                            <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                                                <img v-if="item.photo !== '/img/avatar.png'" :src="item.photo" :alt="item.name">
                                                                <v-icon v-else color="primary" size="22">
                                                                    ${{ 'settingsIcon' }}
                                                                </v-icon>
                                                            </v-list-item-avatar>

                                                            <v-list-item-content>
                                                                <v-list-item-title v-html="item.name"></v-list-item-title>
                                                                <v-list-item-subtitle v-html="formatPhoneNumber(item.phone)"></v-list-item-subtitle>
                                                            </v-list-item-content>

                                                            <v-list-item-action>
                                                                <v-btn
                                                                    elevation="0"
                                                                    icon
                                                                    @click="deleteUserGroup(item)"
                                                                >
                                                                    <v-icon dark>
                                                                        mdi-delete-outline
                                                                    </v-icon>
                                                                </v-btn>
                                                            </v-list-item-action>
                                                        </v-list-item>

                                                        <v-divider :key="index" inset style="position:unset"></v-divider>
                                                    </template>
                                                </v-list-item-group>
                                            </v-list>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>

                            <div class="chat_button_create">
                                <v-btn
                                    class="mx-2"
                                    fab
                                    dark
                                    color="primary"
                                    @click="editChatGroup()"
                                >
                                    <v-icon dark>
                                        mdi-check-outline
                                    </v-icon>
                                </v-btn>
                            </div>
                        </ValidationObserver>
                    </template>
                    <div v-else-if="add_group && !chat_group_name && !edit_group">
                        <v-list flat>
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon @click="add_group = false">mdi-arrow-left-thin</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title v-text="$t('add_group')"></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>

                        <v-list subheader two-line class="pb-16">
                            <v-list-item-group
                                v-model="add_users_group"
                                :multiple="true"
                                active-class=""
                            >
                                <template v-for="(item, index) in adminItems">
                                    <v-subheader v-if="index === 0" v-text="$t('staff')"></v-subheader>

                                    <v-list-item v-slot:default="{ active }" :key="item.title">

                                        <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                            <img v-if="item.photo !== '/img/avatar.png'" :src="item.photo" :alt="item.name">
                                            <v-icon v-else color="primary" size="22">
                                                ${{ 'settingsIcon' }}
                                            </v-icon>
                                        </v-list-item-avatar>

                                        <v-list-item-content>
                                            <v-list-item-title v-html="item.name"></v-list-item-title>
                                            <v-list-item-subtitle v-if="users_group_ids.indexOf(item.id) === -1" v-html="formatPhoneNumber(item.phone)"></v-list-item-subtitle>
                                            <v-list-item-subtitle v-else v-html="$t('already_added_group')"></v-list-item-subtitle>
                                        </v-list-item-content>

                                        <v-list-item-action v-if="users_group_ids.indexOf(item.id) === -1">
                                            <v-checkbox :input-value="active"></v-checkbox>
                                        </v-list-item-action>
                                    </v-list-item>

                                    <v-divider :key="index" inset style="position:unset"></v-divider>
                                </template>
                            </v-list-item-group>
                        </v-list>

                        <div v-if="typeof add_users_group !== 'undefined'" class="chat_button_create">
                            <v-btn
                                class="mx-2"
                                fab
                                dark
                                color="primary"
                                v-if="Array.isArray(add_users_group) && add_users_group.length > 0"
                                @click="addUserGroup()"
                            >
                                <v-icon dark>
                                    mdi-check-outline
                                </v-icon>
                            </v-btn>
                        </div>
                    </div>
                    <template v-else>
                        <v-list subheader class="py-0">
                            <v-list-item>
                                <v-icon @click="closeChat" class="mr-1" color="grey">mdi-chevron-left</v-icon>
                                <v-list-item-avatar size="36" class="border_blue mr-4">
                                    <img v-if="chat_avatar !== '/img/avatar.png'" :src="chat_avatar" :alt="chat_name">
                                    <v-icon v-else color="primary" size="22">
                                        ${{ 'settingsIcon' }}
                                    </v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title v-text="chat_name"></v-list-item-title>
                                    <v-list-item-subtitle v-text="login_name"></v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action v-if="discuss_group_id">
                                    <v-menu offset-y>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                icon
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <v-icon>mdi-dots-vertical</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item
                                                v-for="(item, index) in discuss_chat_menu" :key="index"
                                                @click="handleClickGroup(item.click)"
                                            >
                                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </v-list-item-action>

                            </v-list-item>
                        </v-list>

                        <div class="chat__messages" ref="scrollContainerChatRightCenter">
                            <template v-if="messages.length !== 0">
                                <div  id="book" class="px-0 mx-0">
                                    <v-col v-for="(chatMessages, date) in chatMessagesDate" :key = "date" class="py-0 my-0">
                                        <div class="date_day_week d-flex justify-center" style="display: block; width: 100%">{{ getDate(date) }}</div>
                                        <DiscussChatMessage
                                            v-for="msg in chatMessages" :key="msg.id"
                                            ref="comment"
                                            :msg="msg"
                                            :chat_uuid="chat_uuid"
                                            @deleteMessage="deleteMessage"
                                            @scrollCard="scrollCard"
                                            @replyOpen="replyOpen"
                                            @replyClose="replyClose"
                                            @editMessage="editMessage"
                                        />
                                    </v-col>
                                </div>
                            </template>
                        </div>
                        <div class="chat__input" v-if="this.chat_uuid !== null">
                            <v-card flat>
                                <v-toolbar
                                    class="mb-2 toolbar_reply rounded-0"
                                    height="60px"
                                    elevation="0"
                                    v-if="toolbar_reply"
                                >
                                    <div class="reply">
                                        <div class="pt-0 my-0" v-if="reply_msg.type === 'text'">
                                            <span class="reply_name"> {{ reply_name }} </span>
                                            <span class="font-size-10">{{ reply_msg.value }}</span>
                                        </div>
                                        <div v-if="reply_msg.type === 'image'" class="d-flex mx-0 px-0">
                                            <v-img
                                                v-if="reply_msg.type === 'image'"
                                                class="ml-1 my-0"
                                                max-width="50"
                                                :src="reply_msg.value"
                                            >
                                            </v-img>
                                            <div class="mr-auto pt-0 px-2">
                                                <span class="reply_name">{{ reply_name }}</span>
                                                <span class="reply_name font-size-10">
                                                    {{ $t('photo') }}
                                                    <v-icon left x-small>
                                                        mdi-camera-outline
                                                    </v-icon>
                                                </span>
                                            </div>
                                        </div>
                                        <div v-if="reply_msg.type === 'file'" class="my-0">
                                            <div class="pt-0">
                                                <span class="reply_name">{{ reply_name }}</span>
                                                <span class="font-size-10">
                                                   {{ reply_msg.file_name }}
                                                   <v-icon left x-small>
                                                       mdi-paperclip
                                                   </v-icon>
                                               </span>
                                            </div>
                                        </div>
                                        <div v-if="reply_msg.type === 'sound'" class="my-0">
                                            <div class="pt-0">
                                                <span class="reply_name">{{ reply_name }}</span>
                                                <span class="font-size-10">
                                                    {{ $t('record') }}
                                                    <v-icon left x-small>
                                                        mdi-play-circle-outline
                                                    </v-icon>
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <v-spacer></v-spacer>
                                    <v-toolbar-items>
                                        <v-btn
                                            icon
                                            @click="replyClose"
                                        >
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </v-toolbar-items>
                                </v-toolbar>
                                <v-card v-if="attachmentType && attachmentEntity" >
                                    <v-card-title>
                                        {{ $t(attachmentType + '.self') }}
                                    </v-card-title>
                                    <v-card-text>
                                        <template v-if="attachmentType === 'uved'">
                                            <router-link :to="{name: 'uved.show', params: {id: attachmentEntity.uuid}}">{{ attachmentEntity.name + ' ' + attachmentEntity.bin_iin }}</router-link>
                                        </template>
                                        <template v-if="attachmentType === 'task'">
                                            <router-link :to="{name: 'task.show', params: {id: attachmentEntity.uuid}}">{{ attachmentEntity.title }}</router-link>
                                        </template>
                                        <template v-if="attachmentType === 'deal'">
                                            <router-link :to="{name: 'deal.show', params: {id: attachmentEntity.uuid}}">{{ attachmentEntity.name }}</router-link>
                                        </template>
                                        <v-btn icon @click="clearAttachment"><v-icon>mdi-close</v-icon></v-btn>
                                    </v-card-text>
                                </v-card>
                                <v-textarea
                                    v-model="text"
                                    :label="$t('message')"
                                    no-details
                                    rows="1"
                                    auto-grow
                                    full-width
                                    id="chat-message-typer-textarea"
                                    @keyup.enter="handleEnterKey"
                                    :disabled="loading"
                                    hide-details
                                    class="input_textarea send_msg py-0 my-0"
                                    ref="message-input"
                                >
                                    <template v-slot:append>
                                        <v-menu>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn v-bind="attrs" v-on="on" icon>
                                                    <v-icon>mdi-paperclip</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list class="py-0" dense>
                                                <v-list-item @click="document.getElementById('file').click()">
                                                    <v-list-item-title>{{ $t('files') }}</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="taskDialog = true">
                                                    <v-list-item-title>{{ $t('task.self') }}</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="dealDialog = true">
                                                    <v-list-item-title>{{ $t('deal.self') }}</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item  @click="uvedDialog = true">
                                                    <v-list-item-title>{{ $t('uved.self') }}</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </template>
                                    <template v-slot:append-outer>
                                        <v-btn @click="sendMessage" icon>
                                            <v-icon>mdi-send</v-icon>
                                        </v-btn>
                                    </template>
                                </v-textarea>
                                <v-file-input hide-input prepend-icon=""
                                              v-model="files"
                                              id="file"
                                              multiple
                                              @change="previewFiles"
                                >
                                </v-file-input>
                            </v-card>
                        </div>
                        <DiscussChatEditMessage
                            v-model="editingMessage"
                            @input="editingMessage = null"
                        />
                    </template>

                </div>
            </div>

            <v-dialog v-model="uvedDialog">
                <v-card>
                    <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                        <v-toolbar class="dialog_toolbar" elevation="0">
                            <div class="ma-auto ml-0">
                                <span>{{ $t('uved.attach') }}</span>
                            </div>
                            <div>
                                <v-icon @click="closeAttachement">mdi-close</v-icon>
                            </div>
                        </v-toolbar>

                        <v-card-text>
                            <ValidationProvider ref="attachmentEntityTmp" rules="required" v-slot="{ errors, valid }">
                                <SelectUved
                                    id="uved-attachment"
                                    v-model="attachmentEntityTmp"
                                    @input="selectAttachment"
                                    :error="!valid"
                                ></SelectUved>
                            </ValidationProvider>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <Btn @click="attach('uved')" :disabled="invalid">
                                {{ $t('messenger.attach') }}
                            </Btn>
                        </v-card-actions>
                    </ValidationObserver>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dealDialog">
                <v-card>
                    <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                        <v-toolbar class="dialog_toolbar" elevation="0">
                            <div class="ma-auto ml-0">
                                <span>{{ $t('deal.attach') }}</span>
                            </div>
                            <div>
                                <v-icon @click="closeAttachement">mdi-close</v-icon>
                            </div>
                        </v-toolbar>

                        <v-card-text>
                            <ValidationProvider ref="attachmentEntityTmp" rules="required" v-slot="{ errors, valid }">
                                <SelectDeal
                                    id="deal-attachment"
                                    v-model="attachmentEntityTmp"
                                    @input="selectAttachment"
                                ></SelectDeal>
                            </ValidationProvider>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <Btn @click="attach('deal')" :disabled="invalid">
                                {{ $t('messenger.attach') }}
                            </Btn>
                        </v-card-actions>
                    </ValidationObserver>
                </v-card>
            </v-dialog>

            <v-dialog v-model="taskDialog">
                <v-card>
                    <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                        <v-toolbar class="dialog_toolbar" elevation="0">
                            <div class="ma-auto ml-0">
                                <span>{{ $t('task.attach') }}</span>
                            </div>
                            <div>
                                <v-icon @click="closeAttachement">mdi-close</v-icon>
                            </div>
                        </v-toolbar>

                        <v-card-text>
                            <ValidationProvider ref="attachmentEntityTmp" rules="required" v-slot="{ errors, valid }">
                                <SelectTask
                                    id="task-attachment"
                                    v-model="attachmentEntityTmp"
                                    @input="selectAttachment"
                                ></SelectTask>
                            </ValidationProvider>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <Btn @click="attach('task')" :disabled="invalid">
                                {{ $t('messenger.attach') }}
                            </Btn>
                        </v-card-actions>
                    </ValidationObserver>
                </v-card>
            </v-dialog>

            <v-dialog
                v-model="dialog_file"
                scrollable
                persistent
                transition="dialog-bottom-transition"
                max-width="500px"
            >
                <v-card>
                    <v-toolbar class="dialog_toolbar" elevation="0">
                        <div class="ma-auto ml-0">
                            <span>{{ $t('send_files', {'count': document_urls.length}) }}</span>
                        </div>
                        <div>
                            <v-icon @click="dialog_file = false;document_urls=[];documents=[]">mdi-close</v-icon>
                        </div>
                    </v-toolbar>

                    <v-card-text style="height: 300px;" class="pa-0">
                        <v-list subheader>
                            <v-list-item v-for="document in document_urls" :key="document.id">
                                <v-list-item-icon>
                                    <v-img
                                        max-width="90"
                                        :alt="`${document.name}`"
                                        :src="document.url"
                                        max-height="60"
                                    ></v-img>
                                    <v-icon
                                        x-large
                                        v-if="!mimeTypeImage(document.type)">mdi-file-document-outline mdi-48px
                                    </v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <v-list-item-title v-text="document.name"></v-list-item-title>
                                    <v-list-item-subtitle v-text="(document.size / 1000).toFixed(1) + ' KB'"></v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-icon class="d-flex align-self-center mt-2">
                                    <v-btn
                                        icon
                                        @click="deleteFile(document)"
                                    >
                                        <v-icon>mdi-delete-outline</v-icon>
                                    </v-btn>

                                </v-list-item-icon>
                            </v-list-item>
                        </v-list>


                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="sendMessageFile">
                            {{ $t('sent') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-overlay :value="dragover && chat_uuid !== null" z-index="10" color="#244ba8">
                <v-row>
                    <v-col style="height: 400px;width:500px;"
                           class="grey darken-1"
                           @drop.prevent="dropFile"
                    >
                        <v-col style="height: 380px;width:490px; border: dashed 4px #fff;">

                            <v-card
                                height="350"
                                class="pa-2 grey darken-1 align-self-center"
                                elevation="0"

                            >
                                <v-col sm="12" class="text-center mt-10">
                                    <v-icon
                                        left style="font-size: 150px"

                                        class="text-center">
                                        mdi-file-upload-outline
                                    </v-icon>
                                </v-col>
                                <v-col sm="12" class="text-center font-weight-bold h5">{{ $t('drop_files_here_to_send_them') }}</v-col>


                            </v-card>


                        </v-col>
                    </v-col>
                </v-row>
            </v-overlay>
        </div>
    </PageLayout>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mapActions, mapGetters} from "vuex"
import MakeGroupAvatar from "../components/MakeGroupAvatar";
import PageLayout from "@/components/Leentech/PageLayout.vue";
import Btn from "@/components/Form/Btn.vue";
import SelectUved from "@/components/Form/SelectUved.vue";
import SelectTask from "@/components/Form/SelectTask.vue";
import SelectDeal from "@/components/Form/SelectDeal.vue";
import Teleport from "vue2-teleport";
import TextInput from "@/components/Form/TextInput.vue";
import _ from "lodash";
import moment from "moment/moment";
import DiscussChatMessage from "@/views/DiscussChatMessage.vue";
import NavMixin from "@/plugins/mixins/Nav";
import DiscussChatEditMessage from "@/components/DiscussChatEditMessage.vue";
import message from "element-ui/packages/message";

export default {
    name: "DiscussChats",
    components: {
        DiscussChatEditMessage,
        DiscussChatMessage,
        Btn,
        PageLayout,
        MakeGroupAvatar,
        ValidationProvider,
        ValidationObserver,
        Teleport,
        TextInput,
        SelectUved,
        SelectDeal,
        SelectTask
    },
    mixins: [NavMixin],
    data() {
        return {
            discuss_chat: null,
            code: null,
            sortBy: "last_message_date",
            sortDir: false,
            loading: false,
            options: {},
            page: 1,
            pageCount: 0,
            perPage: 5,
            totalVisiblePag: 7,
            totalDiscussChats: 0,
            discuss_chatItems: [],
            filter_show: false,
            text: null,
            chat_avatar: null,
            chat_name: null,
            // login_name:'+7 707 747 88 99, +7 755 889 88 55',
            login_name: null,
            chat_uuid: null,
            // chatMessagesDate: [],

            messages: [],
            messagesTotal: 0,
            loadingMessage: false,

            reply_msg: {},
            reply_name: null,
            reply_uuid: null,
            discuss_chat_menu: [
                {title: this.$t('quit_band'), click: "quit_band"},
                {title: this.$t('add_group'), click: "add_group"},
                {title: this.$t('edit_group'), click: "edit_group"},
            ],
            toolbar_reply: false,
            add_chat: false,
            adminItems: [],
            contacts: [],
            chat_group: [],
            chat_group_name: false,
            photo: null,
            name_group: null,
            my_chats: [],
            document,
            documents: [],
            files: [],
            document_urls: [],
            dialog_file: false,
            dragover: false,
            add_group: false,
            edit_group: false,
            users_group: [],
            users_group_ids: [],
            add_users_group: [],
            discuss_group_id: null,
            readerTimer: null,
            messageScrollInited: false,
            discussChatCommentsWebsocketInited: false,

            getDiscussChatsTimer: null,
            editingMessage: null,

            taskDialog: false,
            dealDialog: false,
            uvedDialog: false,

            attachmentType: null,
            attachmentEntity: null,

            attachmentTypeTmp: null,
            attachmentEntityTmp: null,
        }
    },
    computed: {
        ...mapGetters(['listLanguages', 'lang', 'itemsPerPage', 'perPageItems', 'userName', 'userAvatar', 'timezone']),

        languages() {
            return this.listLanguages
        },

        chatMessagesDate() {
            return this.messages.reduce((acc, message) => {
                const date = moment(message.date).format("YYYY-MM-DD");

                if (!acc[date]) {
                    acc[date] = [];
                }

                acc[date].push(message);

                return acc;
            }, {});
        },

        userId() {
            return this.$auth.user().id;
        }
    },

    watch: {
        chat_group: {
            handler(newValue) {
                this.contacts = [];
            }
        },

        messages: {
            deep: true,
            handler(newValue) {
                this.updateObserver();

                return newValue;
            }
        },
    },

    async mounted() {
        await this.getDiscussChats()
        await this.getAdmins()
        if (this.$route.params.uuid) {
            let item = this.discuss_chatItems.find(obj => obj.uuid === this.$route.params.uuid);
            this.openChat(item)
        }
        this.discussChatCommentsWebsocket();
        this.initIntersectionObserver();
    },

    destroyed() {
        this.discussChatCommentsWebsocketClose();
        this.destroyMessagesScroll();
    },

    methods: {
        ...mapActions(['fetchNotificationGroups']),

        attach(type) {
            this.attachmentType = type;
            this.attachmentEntity = this.attachmentEntityTmp;

            this.attachmentEntityTmp = null;

            this.closeAttachement()
        },

        selectAttachment(entity) {
            this.attachmentEntityTmp = entity;
        },

        closeAttachement() {
            this.taskDialog = false;
            this.dealDialog = false;
            this.uvedDialog = false;

            this.attachmentEntityTmp = null;
        },

        async getDiscussChats() {
            var _this = this
            this.progress = 0
            this.loading = true

            let params = {}

            if (this.discuss_chat) {
                params.discuss_chat = this.discuss_chat
                params.page = 1
            }
            if (this.code) {
                params.code = this.code
                params.page = 1
            }

            await this.$http
                .get("admin/discuss_chat", {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.discuss_chatItems = res.body.data
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_list_discuss_chats'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }

                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },

        getDiscussChatsWithDelay() {
            if(this.getDiscussChatsTimer) {
                clearTimeout(this.getDiscussChatsTimer)
            }

            this.getDiscussChatsTimer = setTimeout(() => {
                this.getDiscussChats()
            }, 1000)
        },

        deleteMessage(msg) {
            let k = _.findKey(this.messages, {uuid: msg.uuid})
            this.messages.splice(k, 1)
        },

        replyOpen(msg) {
            this.reply_uuid = msg.uuid
            this.reply_msg = msg.comment;
            this.reply_name = msg.name;
            this.toolbar_reply = true;
            this.scrollCard();
        },

        replyClose() {
            this.reply_uuid = null
            this.reply_name = null
            this.toolbar_reply = false
            this.reply_msg = {}
        },

        editMessage(msg) {
            this.editingMessage = msg;
        },

        openChat(item) {
            this.chat_uuid = item.uuid;
            this.chat_name = item.chat.name;
            this.login_name = item.admins_names;
            this.chat_avatar = item.chat.avatar;
            this.photo = item.chat.avatar;
            this.name_group = item.chat.name;
            this.users_group = item.admins;
            this.users_group_ids = item.admin_ids;
            this.discuss_group_id = item.discuss_group;
            this.getMessages(item.uuid, 1).then((body) => {
                this.messages = body.data.reverse();
                this.messagesTotal = body.meta.total
                this.messagesLastPage = body.meta.last_page
                this.page = body.meta.current_page

                this.scrollCard();
            });
            this.initMessagesScroll();
        },

        handleEnterKey(event) {
            if (event.key === 'Enter' && !event.shiftKey && !event.ctrlKey && !event.altKey && !event.metaKey) {
                this.sendMessage();
            } else {
                event.preventDefault();
                if (!event.shiftKey) {
                    this.text += '\n';
                }
            }
        },

        scrollCard() {
            this.$nextTick(() => {
                // Прокрутка до нижней части после обновления списка
                if (this.$refs.scrollContainer !== undefined) {
                    const scrollContainer = this.$refs.scrollContainer;
                    scrollContainer.scrollTop = scrollContainer.scrollHeight;
                }

                // Прокрутка до нижней части после обновления списка
                if (this.$refs.scrollContainerChatRightCenter !== undefined) {
                    const scrollContainerChatRightCenter = this.$refs.scrollContainerChatRightCenter;
                    scrollContainerChatRightCenter.scrollTop = scrollContainerChatRightCenter.scrollHeight;
                }
            });
        },

        initMessagesScroll() {
            this.$nextTick(() => {
                if(this.$refs['scrollContainerChatRightCenter']) {
                    this.$refs['scrollContainerChatRightCenter'].removeEventListener('scroll', this.onMessagesScroll);
                    this.$refs['scrollContainerChatRightCenter'].addEventListener('scroll', this.onMessagesScroll);

                    this.messageScrollInited = true;
                }
            })
        },

        destroyMessagesScroll() {
            if(this.$refs['scrollContainerChatRightCenter']) {
                this.$refs['scrollContainerChatRightCenter'].removeEventListener('scroll', this.onMessagesScroll);
            }
        },

        onMessagesScroll() {
            if(this.$refs['scrollContainerChatRightCenter'].scrollTop === 0) {
                if(this.page < this.messagesLastPage) {
                    let firstMessageNode = null;

                    if(this.$refs['comment'].length > 0) {
                        let id = this.messages[0].id;
                        firstMessageNode = document.querySelector(".chat-message[data-message-id=\"" + id + "\"]");
                    }

                    this.getMessages(this.chat_uuid, this.page + 1).then((body) => {
                        this.messages = [...body.data.reverse(), ...this.messages];
                        this.messagesTotal = body.meta.total
                        this.messagesLastPage = body.meta.last_page
                        this.page = body.meta.current_page

                        setTimeout(() => {
                            this.$nextTick(() => {
                                if (firstMessageNode) {
                                    const scrollContainerChatRightCenter = this.$refs.scrollContainerChatRightCenter;
                                    scrollContainerChatRightCenter.scrollTop = firstMessageNode.offsetTop - firstMessageNode.clientHeight;
                                }
                            });
                        });
                    });
                }
            }
        },

        async sendMessage() {
            const _this = this;
            const formData = new FormData()
            const comment_uuid = this.$uuid.v4();

            this.progress = 0
            this.loading = true

            if (typeof this.text === 'undefined' || this.text === null || this.text === '') {
                return;
            }

            formData.append('comment_uuid', comment_uuid)
            formData.append('chat_uuid', this.chat_uuid)

            if (this.languages && this.languages[this.tab]) {
                formData.append('language', this.languages[this.tab])
            }
            if (this.reply_uuid) {
                formData.append('reply_uuid', this.reply_uuid)
            }
            if (this.text) {
                formData.append('message', this.text)
            }

            if(this.attachmentType && this.attachmentEntity) {
                formData.append('attachment[type]', this.attachmentType)
                formData.append('attachment[entity_uuid]', this.attachmentEntity.uuid)
            }

            // Add
            await this.$http
                .post('admin/chat_comment', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(({ body }) => {
                    this.pushMessageIfNotExist(body.data);

                    this.text = null
                    this.scrollCard();
                    this.replyClose();
                    // this.getDiscussChats();

                    this.clearAttachment();
                })
                .catch(err => {
                    this.$toastr.error(this.$t('message_could_not_be_sent'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false;

                    if(this.$refs['message-input']) {
                        this.$refs['message-input'].focus();
                    }
                })
        },

        getMessages(chat_uuid, page = 1) {
            this.loadingMessage = true;

            let params = {};

            if(page > 1) {
                params.page = page;
            }

            params.chat_uuid = chat_uuid;

            return new Promise((resolve, reject) => {
                this.$http
                    .get("admin/chat_comment", {
                        params: params,
                    })
                    .then(res => {
                        resolve(res.body);
                    })
                    .catch(err => {
                        this.messages = []
                        this.$toastr.error(this.$t('failed_to_get_list_messages'))
                        reject();
                    })
                    .finally(end => {
                        this.loadingMessage = false
                    })
            })
        },

        pushMessageIfNotExist(msg) {
            let messageKey = _.findIndex(this.messages, {uuid: msg.uuid})
            if(messageKey !== -1) {
                this.$set(this.messages, messageKey, msg);
            } else {
                this.messages.push(msg);
                this.scrollCard();
            }
        },

        discussChatCommentsWebsocket() {
            window.Echo
                .private(`App.Models.Admin.${this.$auth.user().id}`)
                .listen(`.Event.DiscussChat`, this.onSocketDiscussChat)
                .listen('.Event.Admin.Messages', this.onSocketAdminMessages)
        },

        discussChatCommentsWebsocketClose() {
            window.Echo
                .private(`App.Models.Admin.${this.$auth.user().id}`)
                .stopListening(`.Event.DiscussChat`, this.onSocketDiscussChat)
                .stopListening(`.Event.Admin.Messages`, this.onSocketAdminMessages);
        },

        onSocketDiscussChat(event) {
            //Если пришло добавление сообщения
            if (event && event.id && event.id > 0) {
                if (event.comment && event.comment.type) {
                    if(this.chat_uuid && event.chat_uuid && this.chat_uuid === event.chat_uuid) {
                        this.pushMessageIfNotExist(event);
                    }
                }
            } else if (event && event.type && event.type === 'delete') {
                // Если пришло удаление сообщение
                if (event.id_array && event.id_array > 0 && event.date) {
                    let k = _.findKey(event.id, this.messages[event.date])

                    if(k) {
                        this.messages[k].splice(event.id, 1);
                    }
                }
            }

            this.getDiscussChatsWithDelay();
        },

        onSocketAdminMessages(event) {
            if (event.data.socket.indexOf('information') !== -1) {
                let item = this.discuss_chatItems.find(obj => obj.uuid === event.data.params.uuid);
                if (this.chat_uuid === item.uuid) {
                    this.openChat(item)
                }
            }
        },

        handleClickGroup(click) {
            switch (click) {
                case 'add_group':
                    this.edit_group = false;
                    this.add_group = true;
                    break;
                case 'edit_group' :
                    this.add_group = false;
                    this.edit_group = true;
                    break;
                case 'quit_band' :
                    this.quitBand()

                    break;
            }
        },

        async getAdmins() {
            this.loading = true
            let params = {};
            // params.active = this.active
            params.filter = 'all_active'
            await this.$http
                .get("admin/admin", {
                    params: params,
                })
                .then(res => {
                    this.adminItems = res.body.data
                })
                .catch(err => {
                    this.adminItems = []
                })
                .finally(end => {
                    this.loading = false
                })
        },

        findIndexByKey(array, key, value) {
            return array.findIndex(obj => obj[key] === value);
        },

        async addChat() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()


            const admin = this.adminItems[this.contacts];

            if (admin.uuid) {
                formData.append('admin_uuid', admin.uuid)
            }

            await this.$http
                .post('admin/discuss_chat', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('discuss_chat_has_been_create'))
                    this.getDiscussChats()
                    this.closeAddChat();
                })
                .catch(err => {
                    this.$toastr.error(this.$t('discuss_chat_has_not_been_create'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },

        async addChatGroup() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()


            if (this.contacts && this.contacts.length > 0) {
                for (let i in this.contacts) {
                    if (this.adminItems[this.contacts[i]].uuid !== undefined && this.adminItems[this.contacts[i]].uuid !== '') {
                        formData.append(`admins[${i}]`, this.adminItems[this.contacts[i]].uuid)
                    }
                }
            }

            if (this.name_group) {
                formData.append('name_group', this.name_group)
            }

            if (this.photo) {
                if (this.photo.length > 250) {
                    var mimeType = this.getMimeType(this.photo)
                    var blob = this.dataURL64toBlob(this.photo)
                    if (mimeType && blob) {
                        formData.append('photo', blob, mimeType)
                    }
                } else {
                    formData.append('photo', this.photo)
                }
            }


            await this.$http
                .post('admin/discuss_chat/group', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('discuss_chat_has_been_create'))
                    this.getDiscussChats()
                    this.closeAddChat();
                })
                .catch(err => {
                    this.$toastr.error(this.$t('discuss_chat_has_not_been_create'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },

        closeAddChat() {
            // this.add_chat = false;
            // this.chat_group = [];
            // this.chat_group_name = false;
            // this.photo = null;
            // this.name_group = null;
            // this.contacts = [];
            // this.add_users_group = [];
            this.add_group = false;
            // this.edit_group = false;
        },

        dropFile(event) {
            this.files = Array.from(event.dataTransfer.files);
            this.previewFiles();
        },

        previewFiles() {
            let i = this.document_urls.length
            let data = {}
            this.files.forEach((file) => {
                data = {}
                data.id = i
                data.url = URL.createObjectURL(file)
                data.name = file.name
                data.blob = file
                data.size = file.size
                data.type = file.type
                this.document_urls.push(data)
                i++
            })
            this.dialog_file = true
        },

        deleteFile(item) {
            let index = this.document_urls.indexOf(item)
            this.document_urls.splice(index, 1)
            if (this.document_urls.length === 0) {
                this.dialog_file = false;
                this.document_urls = [];
                this.documents = []
            }
        },

        async sendMessageFile() {
            var _this = this;
            let formData, blob;
            this.dialog_file = false;
            this.documents = []
            let is_image = false;


            let id_array = 0;
            for (const file of this.document_urls) {

                let replyMessage = {}
                if (this.reply_uuid) {
                    if (this.reply_msg.type === 'text') {
                        replyMessage = {
                            "type": this.reply_msg.type,
                            "name": this.reply_name,
                            "reply_uuid": this.reply_msg.uuid,
                            "value": this.reply_msg.value,
                        }
                    } else {
                        replyMessage = {
                            "type": this.reply_msg.type,
                            "name": this.reply_name,
                            "reply_uuid": this.reply_msg.uuid,
                            "value": this.reply_msg.value,
                            "size": this.reply_msg.size,
                            "type_mime": this.reply_msg.type_mime,
                            "file_type": this.reply_msg.file_type,
                            "file_name": this.reply_msg.file_name,
                        }
                    }

                }
                let comment_uuid = this.$uuid.v4();

                is_image = this.mimeTypeImage(file.type);


                formData = new FormData();
                if (this.reply_uuid) {
                    formData.append('reply_uuid', this.reply_uuid)
                }
                formData.append('comment_uuid', comment_uuid)
                formData.append('id_array', id_array)
                formData.append('chat_uuid', this.chat_uuid)
                formData.append('type_mime', file.type)
                formData.append('size', file.size)
                formData.append('file_name', file.name)
                formData.append('is_image', is_image ? 1 : null)
                file.blob = await this.convertBlobToBase64(file.blob)
                if (file.blob) {
                    blob = this.dataURL64toBlob(file.blob)
                    if (blob) {
                        formData.append('file', blob, file.type)
                    }
                }

                // Add
                this.$http
                    .post('admin/chat_comment/file', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(({ body }) => {
                        this.messages.push(body.data);
                        this.files = [];
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('message_could_not_be_sent'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                    })


            }
            this.scrollCard();
            this.replyClose();
            this.getDiscussChats();

            this.document_urls = [];
            this.count_messages++;
        },

        async addUserGroup() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()

            formData.append('chat_uuid', this.chat_uuid);

            if (this.add_users_group && this.add_users_group.length > 0) {
                for (let i in this.add_users_group) {
                    if (this.adminItems[this.add_users_group[i]].uuid !== undefined && this.adminItems[this.add_users_group[i]].uuid !== '') {
                        formData.append(`admins[${i}]`, this.adminItems[this.add_users_group[i]].uuid)
                    }
                }
            }

            await this.$http
                .post('admin/discuss_chat/group/user', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('participant_has_been_added_to_group'))
                    this.getDiscussChats()
                    this.closeAddChat();
                    if (res && res.body && res.body.data && res.body.data.admins) {
                        this.users_group = res.body.data.admins;
                        this.users_group_ids = res.body.data.admin_ids;
                        this.login_name = res.body.data.admins_names;
                    }
                })
                .catch(err => {
                    this.$toastr.error(this.$t('participant_has_not_been_added_to_group'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },

        async deleteUserGroup(user) {
            this.loading = true;
            let params = {};
            params.chat_uuid = this.chat_uuid;

            await this.$http
                .delete(`admin/discuss_chat/group/user/${user.uuid}`, {
                    params: params,
                })
                .then(res => {
                    this.$toastr.success(this.$t('participant_has_been_remove_to_group'))
                    this.getDiscussChats()
                    if (res && res.body && res.body.data && res.body.data.admins) {
                        this.users_group = res.body.data.admins;
                        this.users_group_ids = res.body.data.admin_ids;
                        this.login_name = res.body.data.admins_names;
                    }
                })
                .catch(err => {
                    this.$toastr.error(this.$t('participant_has_not_been_remove_to_group'))
                })
                .finally(end => {
                    this.loading = false
                })

        },
        async editChatGroup() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()

            if (this.name_group) {
                formData.append('name_group', this.name_group)
            }

            if (this.photo) {
                if (this.photo.length > 250) {
                    var mimeType = this.getMimeType(this.photo)
                    var blob = this.dataURL64toBlob(this.photo)
                    if (mimeType && blob) {
                        formData.append('photo', blob, mimeType)
                    }
                } else {
                    formData.append('photo', this.photo)
                }
            }

            await this.$http
                .put(`admin/discuss_chat/group/${this.chat_uuid}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('group_has_been_edited'))
                    this.getDiscussChats()
                    if (res && res.body && res.body.data && res.body.data.name) {
                        this.chat_name = res.body.data.name;
                        this.chat_avatar = res.body.data.avatar;
                        this.photo = res.body.data.avatar;
                        this.name_group = res.body.data.name;
                    }

                })
                .catch(err => {
                    this.$toastr.error(this.$t('group_has_not_been_edited'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },

        async quitBand() {
            this.loading = true;
            let params = {};
            params.chat_uuid = this.chat_uuid;

            await this.$http
                .delete(`admin/discuss_chat/group/quit`, {
                    params: params,
                })
                .then(res => {
                    this.$toastr.success(this.$t('you_left_group'))
                    this.getDiscussChats()
                    this.closeAddChat()
                    this.chat_uuid = null;
                })
                .catch(err => {
                    this.$toastr.error(this.$t('you_left_not_group'))
                })
                .finally(end => {
                    this.loading = false
                })

        },

        closeChat() {
            this.chat_uuid = null;
        },

        deleteChat(chat) {
            this.$http
                .delete(`admin/discuss_chat/${chat.uuid}`)
                .then(res => {
                    this.getDiscussChats();
                })
                .catch(err => {

                })
                .finally(end => {
                    this.loading = false
                })
        },

        initIntersectionObserver() {
            const options = {
                root: null,
                rootMargin: "0px",
                threshold: 0.1,
            };

            this.observer = new IntersectionObserver(this.handleIntersection, options);
            this.updateObserver();
        },

        async handleIntersection(entries) {
            for (const entry of entries) {
                if (entry.isIntersecting) {
                    const id = parseInt(entry.target.dataset.messageId);
                    const message = _.find(this.messages, {id: id});

                    if (message) {
                        await this.markAsRead(message);
                    }

                    this.observer.unobserve(entry.target);
                }
            }
        },

        updateObserver() {
            // Обновляем наблюдателя для новых комментариев
            this.$nextTick(() => {
                if (this.$refs.comment) {
                    this.$refs.comment.forEach((comment) => {
                        if (comment.$el.classList.contains("chat-message--new") && !comment.$el.classList.contains("chat-message--my")) {
                            this.observer.observe(comment.$el);
                        }
                    });
                }
            });
        },

        markAsRead(msg) {
            let self = this;
            this.$http
                .post(`admin/chat_comment/${msg.id}/reader`)
                .then(({ body }) => {
                    let readerIndex = _.findIndex(msg.readers, {id: body.data.id});

                    if(readerIndex !== -1) {
                        msg.readers[readerIndex] = body.data;
                    } else {
                        msg.readers.push(body.data);
                    }

                    self.fetchNotificationGroupsWithDelay();
                }).catch(err => {
                });
        },

        fetchNotificationGroupsWithDelay() {
            let self = this;

            return new Promise((resolve, reject) => {
                if(self.readerTimer) {
                    clearTimeout(self.readerTimer)
                }

                self.readerTimer = setTimeout(() => {
                    self.fetchNotificationGroups()
                        .then(() => {
                            resolve();
                        }).catch(() => {
                            reject();
                        });

                }, 10000)
            })
        },

        clearAttachment() {
            this.attachmentType = null;
            this.attachmentEntity = null;
        },
    }
}
</script>

<style lang="scss">
.chat__container {
    display: flex;
    background-color: #f3f4f6;
}

.chat_left_top {
    width: 20% !important;
}

.chat_right_top {
    border-left: solid 2px #d2d2d2;
    overflow-x: hidden;
    height: 72px !important;
    flex: 1;
}

.chat_left_center {
    position: relative !important;
    width: 20% !important;
    overflow-y: auto;

}

.chat_right_center {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    border-left: solid 2px #d2d2d2;
    overflow-x: hidden;
}

.chat_messages {
    flex: 1;
    overflow-y: auto;
    background-color: #fff;
    margin-bottom: 60px
}

.chat_button_create2 {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
}

.chat_button_create {
    position: fixed;
    bottom: 65px;
    z-index: 10;
    width: 20% !important;
    text-align: center;
}

.chat__container {
    position: relative;
    width: 100%;
}

.page-layout {
    &.chat-page {
        .page-layout__main {
            position: relative;
        }
    }
}

.chat-page__container {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}

.chat {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
}

.chat__left {
    width: 100%;
    height: 100%;
    overflow-y: auto;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
        width: 30%;

        &--full {
            width: 100%;
        }
    }
}

.chat__right {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
        width: 70%;
        position: relative;
    }
}

.chat__messages {
    overflow-y: auto;
    flex-grow: 1;
    background-color: var(--body);
}

.chat__input {

}

.chat-item__name {
    margin-right: $grid-gutter / 2;
}
.chat-item__new-label {
    background-color: var(--primary);
    width: 16px;
    height: 16px;
    border-radius: 100%;
    display: block;
    font-size: 12px;
    text-align: center;
    color: #fff;
}
</style>
