<script>
import _ from "lodash";
import moment from "moment";
import {mapGetters} from "vuex";

export default {
    name: "DiscussChatMessage",

    props: {
        msg: {
            type: Object,
            required: true
        },
        chat_uuid: {
            type: String,
            required: true
        },
    },

    data() {
        return {
            loading: false,
        };
    },

    computed: {
        ...mapGetters(['lang']),

        isRead() {
            let l = _.find(this.msg.readers, {read_at: null});
            return l === undefined;
        },

        isReadByMe() {
            let l = _.find(this.msg.readers, {read_at: null, id: this.$auth.user().id});
            return l === undefined;
        },

        my() {
            return parseInt(this.msg.admin_id) === parseInt(this.$auth.user().id);
        },

        canEdit() {
            return this.my && this.msg.comment.type === 'text' && !moment(this.msg.created_at).isBefore(moment().subtract(1, 'hour'));
        },

        withAttachment() {
            return this.msg.comment.attachment && this.msg.comment.attachment.entity;
        },

        attachment() {
            if(this.msg.comment && this.msg.comment.attachment) {
                if (this.msg.comment.attachment.type === 'uved') {
                    return {
                        typeName: this.$t('uved.self'),
                        name: this.msg.comment.attachment.entity.name,
                        route: {
                            name: 'uved.show',
                            params: {
                                id: this.msg.comment.attachment.entity.uuid
                            }
                        }
                    }
                } else if (this.msg.comment.attachment.type === 'task') {
                    return {
                        typeName: this.$t('task.self'),
                        name: this.msg.comment.attachment.entity.name,
                        route: {
                            name: 'task.show',
                            params: {
                                id: this.msg.comment.attachment.entity.uuid
                            }
                        }
                    }
                } else if (this.msg.comment.attachment.type === 'deal') {
                    return {
                        typeName: this.$t('deal.self'),
                        name: this.msg.comment.attachment.entity.name,
                        route: {
                            name: 'deal.show',
                            params: {
                                id: this.msg.comment.attachment.entity.uuid
                            }
                        }
                    }
                }
            }

            return null
        },
    },

    methods: {
        handleClick(click, msg) {
            switch (click) {
                case 'delete':
                    this.deleteMessages(msg)
                    break;
                case 'reply' :
                    this.replyOpen(msg)
                    break;
            }
        },

        async deleteMessages(msg) {
            this.loading = true;
            let params = {};
            params.chat_uuid = this.chat_uuid;
            params.message_uuid = msg.uuid;

            await this.$http
                .delete(`admin/chat_comment/${this.chat_uuid}`, {
                    params: params,
                })
                .then(res => {
                    this.$emit('deleteMessage', msg)
                    this.$toastr.success(this.$t('message_has_been_deleted'))
                })
                .catch(err => {
                    this.$toastr.error(this.$t('message_has_not_been_deleted'))
                })
                .finally(end => {
                    this.loading = false
                })

        },

        replyOpen(msg) {
            this.$emit('replyOpen', msg)
        },

        replyClose() {
            this.$emit('replyClose')
        },

        editMessages(msg) {
            this.$emit('editMessage', msg)
        },

        translate() {
            this.$http
                .post(`admin/chat_comment/${this.msg.uuid}/translate`)
                .then(({body}) => {
                    this.msg = body.data
                })
                .catch(err => {
                    this.$toastr.error(this.$t('translation_error'))
                })
        },
    },
}
</script>

<template>
    <v-col class="pa-0 ma-0 chat-message" :class="{'chat-message--new': !isReadByMe, 'chat-message--my': my}" :data-message-id="msg.id" :data-message-date="msg.date">
        <div class="date_day_week" v-if="msg.comment.type === 'bot'">{{ msg.comment.value }}</div>
        <div v-else :id="`div_${msg.uuid}`" class="py-1 mt-7 px-3 mx-0">
            <div class="d-flex">
                <div>
                    <v-avatar size="36" class="border_blue px-0 mx-0">
                        <img v-if="msg.photo !== '/img/avatar.png' && msg.photo !== '' && msg.photo !== null" :src="msg.photo" :alt="msg.name">
                        <v-icon v-else color="primary" size="22">
                            ${{ 'settingsIcon' }}
                        </v-icon>
                    </v-avatar>
                </div>

                <div class="title_subtitle mr-auto px-2">
                    <div>
                        <span class="v-list-item__title">{{ msg.name }}</span>
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon
                                       v-bind="attrs"
                                       v-on="on"
                                       x-small
                                >
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item
                                    @click="replyOpen(msg)"
                                >
                                    <v-list-item-title>{{ $t('reply') }}</v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    v-if="canEdit"
                                    @click="editMessages(msg)"
                                >
                                    <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    v-if="my"
                                    @click="deleteMessages(msg)"
                                >
                                    <v-list-item-title>{{ $t('delete') }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                    <div class="v-list-item__subtitle">{{ formatPhoneNumber(msg.phone) }}</div>
                </div>

                <div class="chat-message__info chat_time">
                    <div class="chat-message__new-label" v-if="(!isReadByMe)"></div>
                    <v-icon v-if="my" class="mx-0 px-0 pr-1 my-0 py-0" right :color="isRead ? 'primary' : ''">
                        {{ msg.delivered ? 'mdi-check-all' : 'mdi-check' }}
                    </v-icon>
                    <span>
                        {{ $moment(msg.created_at).format('HH:mm') }}
                    </span>
                </div>
            </div>

            <v-row class="chat_text_row">
                <v-col cols="12" class="px-2 pb-1 ">
                    <div v-if="msg.reply" class="pb-2">
                        <div v-if="msg.reply.type === 'text'" class="rounded-lg rounded-r-0 py-2 d-inline-flex reply_background">
                            <div class="mr-auto pt-2 px-2">
                                <span class="reply_name">{{ msg.reply.name }}</span>
                                <span class="font-size-10 reply_name">{{ msg.reply.value }}</span>
                            </div>
                        </div>
                        <div v-if="msg.reply.type === 'image'" class="rounded-lg rounded-r-0 py-2 d-inline-flex reply_background">
                            <v-img v-if="msg.reply.type === 'image'"
                                   class="my-0 py-0 ml-2"
                                   max-width="50"
                                   :src="msg.reply.value"
                            >
                            </v-img>
                            <div class="pt-2 px-2">
                                <span class="reply_name">{{ msg.reply.name }}</span>
                                <span class="reply_name font-size-10">
                                    {{ $t('photo') }}
                                    <v-icon left x-small>
                                       mdi-camera-outline
                                    </v-icon>
                                </span>
                            </div>

                        </div>
                        <div v-if="msg.reply.type === 'file'" class="rounded-lg rounded-r-0 py-2 d-inline-flex reply_background">
                            <div class="mr-auto pt-2 px-2">
                                <span class="reply_name">{{ msg.reply.name }}</span>
                                <span class="reply_name font-size-10">
                                    {{ msg.reply.file_name }}
                                    <v-icon left x-small>
                                       mdi-paperclip
                                    </v-icon>
                                </span>
                            </div>
                        </div>
                        <div v-if="msg.reply.type === 'sound'" class="rounded-lg rounded-r-0 py-2 d-inline-flex reply_background">
                            <div class="mr-auto pt-2 px-2">
                                <span class="reply_name">{{ msg.reply.name }}</span>
                                <span class="reply_name font-size-10 pt-1">
                                    <av-waveform
                                        class="canvas_hide"
                                        :canv-width="0"
                                        :canv-height="0"
                                        :audio-src="msg.comment.value">
                                    </av-waveform>
                                    {{ $t('record') }}
                                    <v-icon left x-small>
                                        mdi-play-circle-outline
                                    </v-icon>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div v-if="attachment" class="chat-message__attachment">
                        <RouterLink :to="attachment.route">
                            {{ attachment.typeName }}: {{ attachment.name }}
                        </RouterLink>
                    </div>

                    <div v-if="msg.comment.type === 'text'" class="chat_text">
                        {{ msg.comment.value }}
                        <div style="background-color: #F5F5F5;padding: 20px;" v-if="msg.comment.translations && msg.comment.translations[lang]">
                            {{ msg.comment.translations[lang].value }}
                        </div>
                        <v-btn v-else icon :disabled="loading" @click="translate" :title="$t('translate')">
                            <v-icon>mdi-google-translate</v-icon>
                        </v-btn>
                    </div>
                    <div v-if="msg.comment.type === 'image'" class="chat_text">
                        <v-img
                            class="mb-3 cursor_pointer"
                            max-width="250"
                            :src="msg.comment.value"
                            @click="ImageView(msg)"
                        >
                            <v-row
                                v-if="msg.delivered ? false : true"
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                            >
                                <v-progress-circular
                                    indeterminate
                                    color="grey lighten-5"
                                ></v-progress-circular>
                            </v-row>
                        </v-img>
                    </div>
                    <div v-if="msg.comment.type === 'file'" class="chat_text">
                        <v-btn
                            color="primary"
                            class="white--text"
                            :href="msg.comment.value"
                            download
                        >
                            <v-icon
                                left
                                dark
                            >
                                {{ !msg.delivered ? 'mdi-loading mdi-spin' : 'mdi-cloud-upload' }}
                            </v-icon>

                            {{ msg.comment.file_name }} ({{ (Number(msg.comment.size) / 1000).toFixed(1) + ' KB' }} )
                        </v-btn>
                    </div>
                    <div v-if="msg.comment.type === 'sound'" class="chat_text">
                        <av-waveform
                            class="canvas_hide"
                            :canv-width="0"
                            :canv-height="0"
                            :audio-src="msg.comment.value">
                        </av-waveform>
                    </div>
                </v-col>
            </v-row>
        </div>
    </v-col>
</template>

<style scoped lang="scss">
.chat-message__new-label {
    background-color: var(--primary);
    border-radius: 100%;
    width: 8px;
    height: 8px;
    margin-right: 5px;
}

.chat-message__info {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.chat-message__attachment {
    background-color: #fff;
    border-radius: 6px;
    padding: $grid-gutter / 4 $grid-gutter / 2;
    border-left: 4px solid var(--primary);
}


</style>
